.option-container {
	display: flex;
	align-items: center;

	.option-logo {
		border-radius: 15px;
		max-width: 25px;
		margin-right: 10px;
	}

	div.option-logo {
		background-repeat: no-repeat;
		border-radius: unset;
	}

	.option-label {
		display: flex;
	}
}
