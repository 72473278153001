@import './media_query';
// Here you can add other styles

.selected-image {
	border: 5px solid $success;
}

.selected-main-image {
	border: 7px solid #ffc107;
}

.main {
	margin-right: 50px;
	.container-fluid {
		padding-left: 15px;
		padding-right: 15px;
	}
	@media (max-width: '1024px') {
		margin-right: 0;
	}
}

.app-header {
	.dropdown-item {
		min-width: auto;
	}

	@media (max-width: '550px') {
		height: auto !important;
	}
}

.modal-backdrop.show {
	opacity: 0.7;
}

// Add class to make + sign appear of your element
.add-item {
	position: relative;
	height: 100%;

	&:hover {
		&:before {
			content: '';
			background: rgba(0, 0, 0, 0.2);
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			cursor: pointer;
		}

		&:after {
			content: '\f067';
			font-family: 'FontAwesome', sans-serif;
			font-size: 40px;
			color: $white;
			position: absolute;
			left: calc(50% - 16px);
			top: calc(50% - 20px) !important;
			line-height: normal;
			cursor: pointer;
		}

		&.selected-item:after {
			content: '';
		}
	}

	img {
		text-indent: -90000px;
		overflow: hidden;
		background-image: url('../../assets/img/image-placeholder.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		height: 100%;
	}
}

.crop-button {
	margin-top: 5px;
}

.edit-button {
	margin-top: 5px;
}

// Add class to have an expand icon appear over your element
.expand-item {
	position: relative;

	&:hover {
		&:before {
			content: '';
			background: rgba(0, 0, 0, 0.2);
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			cursor: pointer;
		}

		&:after {
			content: '\f00e';
			font-family: 'FontAwesome', sans-serif;
			font-size: 40px;
			color: $white;
			position: absolute;
			left: calc(50% - 18px);
			top: calc(50% - 20px);
			line-height: normal;
			cursor: pointer;
		}
	}
}

.add-item {
	position: relative;

	&:hover {
		&:before {
			content: '';
			background: rgba(0, 0, 0, 0.2);
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			cursor: pointer;
		}

		&:after {
			content: '\f067';
			font-family: 'FontAwesome', sans-serif;
			font-size: 40px;
			color: $white;
			position: absolute;
			left: calc(50% - 18px);
			top: calc(50% - 20px);
			line-height: normal;
			cursor: pointer;
		}
	}
}

// Override form inputs
.form-control {
	border-color: #cccccc;
	border-radius: 4px;
	height: calc(1.5em + 0.75rem + 5px);

	&::-webkit-input-placeholder {
		/* WebKit browsers */
		color: #cccccc;
	}

	&:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: #cccccc;
	}

	&::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: #cccccc;
	}

	&:-ms-input-placeholder {
		/* Internet Explorer 10+ */
		color: #cccccc;
	}

	&:focus {
		border-color: #2684ff;
		box-shadow: 0 0 0 1px #2684ff;
		outline: 0 none;
	}
}

// Override react input styles
.react-tagsinput {
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;

	&:focus,
	&.react-tagsinput--focused {
		border-color: #2684ff;
		box-shadow: 0 0 0 1px #2684ff;
		outline: 0 none;
	}

	.react-tagsinput-tag {
		border: none !important;
		color: #333333 !important;
		background-color: #e6e6e6 !important;
		padding: 3px 23px 3px 6px !important;
		position: relative;
		font-size: 85% !important;

		.react-tagsinput-remove {
			position: absolute;
			width: 10px;
			height: 100%;
			top: 0;
			right: 0;
			bottom: 0;
			padding: 0px 5px !important;
			-webkit-box-sizing: content-box;
			-moz-box-sizing: content-box;
			box-sizing: content-box;
			font-size: 120%;

			&:hover {
				background-color: #ffbdad !important;
				color: #de350b !important;
			}

			&:before {
				padding: 0 !important;
				margin: 3px 0 0 0 !important;
			}
		}
	}
}

.flatpickr-calendar {
	.flatpickr-month,
	.flatpickr-weekdays,
	.flatpickr-weekday,
	.flatpickr-day.selected,
	.flatpickr-monthDropdown-months,
	.flatpickr-calendar.arrowTop:before {
		background-color: $primary !important;
		border-color: $primary !important;
	}

	&.arrowTop:after {
		border-bottom-color: $primary !important;
	}
}

.nav-tabs-wrapper {
	.nav-tabs {
		.nav-item {
			.nav-link {
				&.active {
					border-bottom-color: #e4e5e6;
					font-weight: bold;
				}
			}
		}
	}
}

.font-size-13 {
	font-size: 13px;
}

.opacity-06 {
	opacity: 0.6;
}

.opacity-03 {
	opacity: 0.3;
}

.opacity-02 {
	opacity: 0.2;
}

.opacity-1 {
	opacity: 1;
}

.top-shadow {
	&::before {
		content: '';
		position: absolute;
		top: -10px;
		left: 0;
		width: 100%;
		height: 10px;
		box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
		z-index: 100;
	}
}

.background-transition {
	transition: background 0.3s ease-in-out;
}

.modified-card-container {
	margin-bottom: 6rem;
}

.pointer-events-allowed {
	pointer-events: all !important;
}

@include tablet {
	.tablet-d-none {
		display: none !important;
	}
}

@include phone {
	.mobile-d-none {
		display: none !important;
	}
}
