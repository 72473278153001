.add-block {
	.label-collapse {
		cursor: pointer;
		padding: 10px;
		outline: none;
		width: 100%;
		font-weight: 600;
		border-top: 1px solid #e2e4e7;
		font-size: 18px;
		margin-bottom: 0;
		//disable text selection
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Old versions of Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
		&:hover {
			background-color: #f3f4f5;
		}
	}

	.block-add-button {
		cursor: pointer;
		//disable text selection
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Old versions of Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
		text-align: center;

		display: block;
		width: calc(33.33% - 10px);
		padding: 0;
		margin: 5px;

		&:hover {
			background-color: #f3f4f5;
		}

		.components-button {
			display: flex;
			flex-direction: column;
			width: 100%;
			font-size: 13px;
			color: #32373c;
			padding: 6px;
			align-items: stretch;
			justify-content: center;
			cursor: pointer;
			background: transparent;
			word-break: break-word;
			border-radius: 2px;
			border: 1px solid transparent;
			transition: all 0.05s ease-in-out;
			position: relative;
			height: auto;
		}

		.block-add-button-icon {
			font-size: 18px;
			padding: 10px 20px;
			border-radius: 2px;
			color: #1e1e1e;
			transition: all 0.05s ease-in-out;
		}
		.block-add-button-name {
			padding: 0px 0px 5px;
			font-size: 12px;
		}
	}
}

.shortcut-btn {
	max-height: 34.8px;
}
.add-block-tooltip {
	.tooltip {
		background-color: transparent;
		width: 300px;
		opacity: 1;
		margin: 0 12px 0 0;
	}
	.tooltip-inner {
		color: #000000;
		padding: 0;
		margin: 0;
		background-color: #fff;
	}
	.arrow {
		display: none;
	}
	.card {
		width: 300px;
		background-color: #f3f4f5;
	}
	.card-body {
		padding: 16px;
		text-align: left;
		background-color: #fff;
		border-top: 1px solid #f3f4f5;
	}
	.card-image {
		padding: 16px;
	}
	.card-title {
		font-size: 14px;
		font-weight: bold;
	}
	.card-title > i {
		margin: 0 5px 0 0;
	}
}

@media screen and (max-width: 700px) {
	.add-block-tooltip {
		.tooltip {
			display: none;
		}
	}
}

.category-listing {
  width: 400px;
}
