$borderStyle: 1px solid #D9D9D9;


.custom-search-date-wrapper {
    display: flex;
    flex-direction: column;
    label {
        font-family: Open Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        color: #434343;
    }

    .custom-search-date-input-wrapper{
        display: inline-flex;
        justify-content: space-between;
        border-radius: 6px;
        border: $borderStyle;  

        .custom-search-date-input {
            text-indent: 12px;
            width: calc(100% - 80px);
            display: flex;
            padding-right: 12px;
            height: auto;
        }
        
        .custom-search-date-input-left-arrow, .custom-search-date-input-right-arrow{
            display: inline-flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            height: 40px;
            width: 40px;
        }

        .custom-search-date-input-left-arrow {
            border-right: $borderStyle;
        }

        .custom-search-date-input-right-arrow {
            border-left: $borderStyle;
        }
    }
}
