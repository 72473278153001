.main-media-images-list {
  margin-bottom: 18px;
  background-color: #F1F3F5;

  .additional-image {
    border-radius: 25%;
    max-width: 80px;
    max-height: 80px;
    margin-right: 10px;
  }
}

.image-operations-container {
  flex-direction: row;
}

.image-upload-operations-container {
  flex-direction: row;
  margin-top: 5px;
}

.main-media-additional-image-info-card-row-description {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}

