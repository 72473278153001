.content-item-list-data {
	p {
		margin: 0;
	}

	small {
		display: flex;
		flex-flow: column;
	}
}

.action-buttons {
	@media (min-width: 556px) {
		display: contents;
	}

	@media (max-width: 556px) {
		margin-top: 10px;
	}
}
