@import '../../../../../../scss/custom/variables';

.datepicker-container {
	position: relative;
}

.date-picker {
	display: block;
	padding: 6px 12px;
	color: $datepicker-input-color;
	font-family: inherit;
	font-size: 14px;
	border-radius: 4px;
	border: 1px solid $datepicker-input-border;
	width: 100%;
}

.close-button {
	position: absolute;
	right: 0;
	bottom: 0;
	border-radius: 4px;
	padding: 7px 10px;
	transition: color 150ms;
	cursor: pointer;

	&:hover .close-icon {
		color: $icon-color-hover;
	}
}

.close-button-audit-log-container {
	right: 0;
	bottom: 0;
	margin-left: -28px;
	margin-top: 2px;
	border-radius: 4px;
	padding: 7px 10px;
	transition: color 150ms;
	cursor: pointer;

	@media (max-width: 556px) {
		margin-bottom: 0.5rem !important;
	}

	&:hover .close-icon {
		color: $icon-color-hover;
	}
}

.close-button-audit-log-page {
	position: absolute;
	right: 0;
	bottom: 0;
	margin-bottom: 1.5rem !important;
	border-radius: 4px;
	padding: 7px 10px;
	transition: color 150ms;
	cursor: pointer;

	@media (max-width: 556px) {
		margin-bottom: 0.5rem !important;
	}

	&:hover .close-icon {
		color: $icon-color-hover;
	}
}

.close-icon {
	color: $icon-color;
}
