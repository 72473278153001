
.limit-exceeded {
  color: #f44336;
}

.summary {
  border-width: 2px;
}


