// Variable overrides
$grid-gutter-width: 10px;
$theme-colors: (
	'primary': #039be5,
	'secondary': #353a48,
	'success': #4caf50,
	'danger': #f44336,
	'warning': #ffc108,
	'info': #4fc2f7,
	'dark': #1d2129,
);
$input-color: #222222;
$sidebar-bg: #2d323e;
$icon-color: #cccccc;
$icon-color-hover: #999999;
$datepicker-input-color: #5c6873;
$datepicker-input-border: #cccccc;
