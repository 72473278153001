.linked-entity-pill {
	display: inline-block;
	font-size: 12px;
	border-radius: 5px;
	border: 1px solid #e3e3e3;
	overflow: hidden;
	padding-left: 5px;
	cursor: pointer;

	.linked-entity-pill-name {
		display: inline-block;
	}

	.linked-entity-pill-remove {
		width: 20px;
		height: 100%;
		display: inline-block;
		background-color: #e3e3e3;
		color: #575b5e;
		text-align: center;
	}
}

.tournament-border {
	border-left: 2px solid #adccf7;
	border-bottom: 2px solid #adccf7;
}

.player-border {
	border-left: 2px solid #27db3f;
	border-bottom: 2px solid #27db3f;
}

.team-border {
	border-left: 2px solid #fc1111;
	border-bottom: 2px solid #fc1111;
}

.coach-border {
	border-left: 2px solid #215bac;
	border-bottom: 2px solid #215bac;
}

.team_president-border {
	border-left: 2px solid #ffea2c;
	border-bottom: 2px solid #ffea2c;
}

.venue-border {
	border-left: 2px solid #000000;
	border-bottom: 2px solid #000000;
}
