// Styles
// CoreUI Icons Set
@import '~@coreui/icons/css/coreui-icons.css';
// Import Flag Icons Set
@import '~flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
//@import '~font-awesome/style/font-awesome.min.style';
@import 'src/assets/fontawesome/scss/fontawesome.scss';
@import 'src/assets/fontawesome/scss/v4-shims.scss';
@import 'src/assets/fontawesome/scss/_core.scss';

@import './assets/fontawesome/css/all.css';
@import './assets/fontawesome/css/v4-shims.css';
@import './assets/fontawesome/css/v5-font-face.css';

// Import Simple Line Icons Set
@import '~simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
@import './scss/style.scss';

@import '../node_modules/fansunited-widget-top-x/style.css';
@import '../node_modules/fansunited-widget-match-quiz/style.css';
@import '../node_modules/fansunited-widget-leaderboard/style.css';

.ReactModal__Overlay {
  z-index: 4000 !important;
}
